



























import { Component, Prop, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action, Getter } from 'vuex-class';
import ArbiterZone from '@/core/interfaces/ArbiterZone';
import Barn from '@/core/interfaces/Barn';

@Component({
    components: { FormFooter },
})
export default class ArbiterZoneForm extends FormBase<ArbiterZone> {
    @Prop({ required: true, type: Number })
    farmId!: number;

    @Action('saveArbiterZone')
    saveAction!: (arbiterZone: Partial<ArbiterZone>) => Promise<ArbiterZone>;

    @Getter('barnsByFarm')
    barnsByFarm!: (id: number) => Barn[];

    name = '';

    barnId: number | null = null;

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
        this.barnId = this.initial.barn_id || null;
    }

    get parseForm(): Partial<ArbiterZone> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
            barn_id: this.barnId,
        };
    }
}

