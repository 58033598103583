















import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action } from 'vuex-class';
import GroundType from '@/core/interfaces/GroundType';

@Component({
    components: { FormFooter },
})
export default class GroundTypeForm extends FormBase<GroundType> {
    @Action('saveGroundType')
    saveAction!: (barn: Partial<GroundType>) => Promise<GroundType>;

    name = '';

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
    }

    get parseForm(): Partial<GroundType> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
        };
    }
}

