











import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import AvailableVehicleType from '@/core/interfaces/AvailableVehicleType';
import FormBase from '@/mixins/FormBase';
import { Action } from 'vuex-class';

@Component({
    components: { FormFooter },
})
export default class FarmVehicleTypeSettings extends FormBase<AvailableVehicleType> {
    @Action('saveAvailableVehicleType')
    saveAction!: (barn: Partial<AvailableVehicleType>) => Promise<AvailableVehicleType>;

    minKgPerMeter = 0;

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.minKgPerMeter = this.initial.min_kg_per_meter || 0;
    }

    get parseForm(): Partial<AvailableVehicleType> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            min_kg_per_meter: this.minKgPerMeter || 0,
        };
    }
}

