


































import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action, Getter } from 'vuex-class';
import FeedType from '@/core/interfaces/FeedType';
import VehicleType from '@/core/interfaces/VehicleType';

@Component({
    components: { FormFooter },
})
export default class FeedTypeForm extends FormBase<FeedType> {
    @Action('saveFeedType')
    saveAction!: (barn: Partial<FeedType>) => Promise<FeedType>;

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    name = '';

    vehicleTypeId = 0;

    dryMatterPercentage = 0;

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
        this.dryMatterPercentage = this.initial.dry_matter_percentage || 0;
        this.vehicleTypeId = this.initial.vehicle_type_id || 0;
    }

    get parseForm(): Partial<FeedType> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
            dry_matter_percentage: this.dryMatterPercentage,
            vehicle_type_id: this.vehicleTypeId,
        };
    }
}

