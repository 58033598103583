

























import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import GroundTypeForm from '@/components/grasscalendar/GroundTypeForm.vue';
import GroundType from '@/core/interfaces/GroundType';

@Component({
    components: {
        GroundTypeForm, Overview,
    },
})
export default class GroundTypesView extends OverviewTable<GroundType> {
    @Getter('groundTypes')
    groundTypes!: GroundType[];

    @Action('deleteGroundType')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('groundTypeById')
    groundTypeById!: (id: number) => GroundType;

    editGroundType: Partial<GroundType> | null = null;

    get columns(): LelyTableColumnsConfig<GroundType> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Name',
            },
        ];
    }

    openGroundTypeForm(groundType: GroundType): void {
        this.editGroundType = groundType;
        this.$bvModal.show('ground-type-form');
    }

    showCreateModal(): void {
        this.editGroundType = {};
        this.$bvModal.show('ground-type-form');
    }
}

