







































































import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action } from 'vuex-class';
import VehicleType from '@/core/interfaces/VehicleType';
import axios from 'axios';

@Component({
    components: { FormFooter },
})
export default class VehicleTypeForm extends FormBase<VehicleType> {
    @Action('saveVehicleType')
    saveAction!: (barn: Partial<VehicleType>) => Promise<VehicleType>;

    name = '';

    minAllowedFillweight = 0;

    maxAllowedFillweight = 0;

    canCombineFeedTypes = false;

    mapIconFile: File | null = null;

    mapIconPreview: string | null = null;

    mapIconWidthPx = 0;

    mapIconHeightPx = 0;

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
        this.canCombineFeedTypes = this.initial.can_combine_feed_types || false;
        this.mapIconHeightPx = this.initial.map_icon_height_px || 0;
        this.mapIconWidthPx = this.initial.map_icon_width_px || 0;
        this.minAllowedFillweight = this.initial.min_allowed_fillweight || 0;
        this.maxAllowedFillweight = this.initial.max_allowed_fillweight || 0;
        this.mapIconWidthPx = this.initial.map_icon_width_px || 0;

        this.fetchMapIcon();
    }

    async fetchMapIcon(): Promise<void> {
        if (!this.exists) {
            return;
        }

        const blob = (await axios.get<Blob>(this.mapIconUrl, { responseType: 'blob' })).data;

        if (!blob || blob.size === 0) {
            return;
        }

        this.mapIconPreview = URL.createObjectURL(blob);
    }

    async beforeSave(): Promise<void> {
        if (!this.mapIconFile) {
            return;
        }

        const formData = new FormData();
        formData.set('file', this.mapIconFile);

        await axios.put(this.mapIconUrl, formData);
    }

    get parseForm(): Partial<VehicleType> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
            can_combine_feed_types: this.canCombineFeedTypes,
            map_icon_height_px: this.mapIconHeightPx,
            map_icon_width_px: this.mapIconWidthPx,
            min_allowed_fillweight: this.minAllowedFillweight,
            max_allowed_fillweight: this.maxAllowedFillweight,
        };
    }

    get mapIconUrl(): string {
        return `/admin/vehicle-types/${this.initial.id}/map-icon`;
    }
}

