import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import state from '@/store/state';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

Vue.use(Vuex);

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
    plugins: [createPersistedState({
        paths: [
            'farms',
            'users',
            'availableVehicleTypes',
            'availableVehicleTypeActions',
            'vehicleTypes',
            'vehicles',
            'barns',
            'fences',
            'feedTypes',
            'vehicleActions',
            'timeZones',
            'roles',
            'users',
            'userGroups',
            'farmGroups',
            'groundTypes',
            'cropTypes',
        ],
    })],
});

export default store;
