

























import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import CropType from '@/core/interfaces/CropType';
import CropTypeForm from '@/components/grasscalendar/CropTypeForm.vue';

@Component({
    components: {
        CropTypeForm, Overview,
    },
})
export default class CropTypesView extends OverviewTable<CropType> {
    @Getter('cropTypes')
    cropTypes!: CropType[];

    @Action('deleteCropType')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('cropTypeById')
    cropTypeById!: (id: number) => CropType;

    editCropType: Partial<CropType> | null = null;

    get columns(): LelyTableColumnsConfig<CropType> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Name',
            },
        ];
    }

    openCropTypeForm(cropType: CropType): void {
        this.editCropType = cropType;
        this.$bvModal.show('crop-type-form');
    }

    showCreateModal(): void {
        this.editCropType = {};
        this.$bvModal.show('crop-type-form');
    }
}

