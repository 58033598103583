






















import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action, Getter } from 'vuex-class';
import FarmGroup from '@/core/interfaces/FarmGroup';
import Farm from '@/core/interfaces/Farm';

@Component({
    components: { FormFooter },
})
export default class FarmGroupForm extends FormBase<FarmGroup> {
    @Action('saveFarmGroup')
    saveAction!: (farmGroup: Partial<FarmGroup>) => Promise<FarmGroup>;

    @Getter('farms')
    farms!: Farm[];

    @Getter('farmsByFarmGroup')
    farmsByFarmGroup!: (farmGroupId: number) => Farm[];

    name = '';

    farmIds: number[] = [];

    @Watch('initial', { immediate: true })
    async setFromInitial(): Promise<void> {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';

        this.farmIds = this.initial.id
            ? this.farmsByFarmGroup(this.initial.id).map(farm => farm.id)
            : [];
    }

    async afterSave(farmGroup: FarmGroup): Promise<void> {
        await this.$store.dispatch('saveFarmFarmGroupsByFarmGroup', {
            farmGroupId: farmGroup.id,
            farmIds: this.farmIds,
        });
    }

    get parseForm(): Partial<FarmGroup> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
        };
    }
}

