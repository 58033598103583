



















import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action } from 'vuex-class';
import Fence from '@/core/interfaces/Fence';

@Component({
    components: { FormFooter },
})
export default class FenceForm extends FormBase<Fence> {
    @Action('saveFence')
    saveAction!: (fence: Partial<Fence>) => Promise<Fence>;

    name = '';

    vectorId: number | null = null;

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';

        this.vectorId = Number(this.initial.vector_fence_id) || null;
    }

    get parseForm(): Partial<Fence> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
            vector_fence_id: Number(this.vectorId) || null,
        };
    }
}

