





























































import { Component, Watch } from 'vue-property-decorator';
import Farm from '@/core/interfaces/Farm';
import { Action, Getter } from 'vuex-class';
import Vehicle from '@/core/interfaces/Vehicle';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import VehicleType from '@/core/interfaces/VehicleType';
import AvailableVehicleType from '@/core/interfaces/AvailableVehicleType';
import TimeZone from '@/core/interfaces/TimeZone';
import FarmGroup from '@/core/interfaces/FarmGroup';
import User from '@/core/interfaces/User';
import UserGroup from '@/core/interfaces/UserGroup';
import arrayUnique from 'array-unique';
import Module from '@/core/interfaces/Module';

@Component({
    components: { FormFooter },
})
export default class FarmForm extends FormBase<Farm> {
    @Getter('vehiclesByFarm')
    vehiclesByFarm!: (id: number) => Vehicle[];

    @Getter('vehicleTypesByFarm')
    vehicleTypesByFarm!: (id: number) => VehicleType[];

    @Getter('availableVehicleTypesByFarm')
    availableVehicleTypesByFarm!: (id: number) => AvailableVehicleType[];

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @Getter('timeZones')
    timeZones!: TimeZone[];

    @Getter('farmGroups')
    farmGroups!: FarmGroup[];

    @Getter('farmGroupsByFarm')
    farmGroupsByFarm!: (id: number) => FarmGroup[];

    @Getter('userGroupsByFarmGroup')
    userGroupsByFarmGroup!: (id: number) => UserGroup[];

    @Getter('usersByFarm')
    usersByFarm!: (id: number) => User[];

    @Getter('usersByUserGroup')
    usersByUserGroup!: (id: number) => User[];

    @Getter('modules')
    modules!: Module[];

    @Action('saveFarm')
    saveAction!: (payload: Partial<Farm>) => Promise<Farm>;

    @Action('saveAvailableVehicleType')
    saveAvailableVehicleType!: (payload: Partial<AvailableVehicleType>) => Promise<void>;

    @Action('deleteAvailableVehicleType')
    deleteAvailableVehicleType!: (payload: number) => Promise<void>;

    name = '';

    codeName = '';

    movexNumber = '';

    license = '';

    timezone_id = 0;

    plannerVersion = 0;

    farmGroupIds: number[] = [];

    saving = false;

    moduleIds: number[] = [];

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
        this.codeName = this.initial.code_name || '';
        this.license = this.initial.farm_license || '';
        this.timezone_id = this.initial.timezone_id || 1;
        this.plannerVersion = this.initial.planner_version || 1;
        this.moduleIds = this.initial.module_ids || [];
        this.movexNumber = this.initial.movex_number || '';
    }

    @Watch('initialFarmGroupIds', { immediate: true })
    setFarmGroupIds(): void {
        this.farmGroupIds = this.initialFarmGroupIds;
    }

    get initialFarmGroupIds(): number[] {
        return this.initial.id ? this.farmGroupsByFarm(this.initial.id).map(group => group.id) : [];
    }

    async afterSave(farm: Farm): Promise<void> {
        await this.$store.dispatch('saveFarmFarmGroupsByFarm', {
            farmId: farm.id,
            farmGroupIds: this.farmGroupIds,
        });
    }

    get parseForm(): Partial<Farm> {
        return {
            id: this.initial?.id || undefined,
            name: this.name,
            code_name: this.codeName,
            farm_license: this.license,
            timezone_id: this.timezone_id,
            planner_version: this.plannerVersion,
            module_ids: this.moduleIds,
            movex_number: this.movexNumber,
        };
    }

    get vehicles(): Vehicle[] {
        if (!this.initial?.id) {
            return [];
        }

        return this.vehiclesByFarm(this.initial.id);
    }

    get users(): User[] {
        return arrayUnique(
            [
                ...this.initial?.id ? this.usersByFarm(this.initial.id) : [],
                ...this.farmGroupIds
                    .flatMap(farmGroupId => this.userGroupsByFarmGroup(farmGroupId))
                    .flatMap(userGroup => this.usersByUserGroup(userGroup.id)),
            ],
        ).sort((a, b) => a.username.localeCompare(b.username));
    }
}

